// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { TreadTypes, FlareUpgrades, Materials, FalseFronts, RoughOpeningSizes, CustomerTypes, Staircases, Risers, Treads, Price } = initSchema(schema);

export {
  TreadTypes,
  FlareUpgrades,
  Materials,
  FalseFronts,
  RoughOpeningSizes,
  CustomerTypes,
  Staircases,
  Risers,
  Treads,
  Price
};