import React, { useEffect } from 'react';
import {
    AmplifyProvider,
    Authenticator,
    Button,
    Card,
    Flex,
    Heading,
    SelectField,
    StepperField,
    Text,
    useTheme
} from '@aws-amplify/ui-react';
import { DataStore } from '@aws-amplify/datastore';
import { CustomerTypes, FalseFronts, Risers, RoughOpeningSizes, Staircases, Treads } from './models';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import create from 'zustand';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsExports);

const useStore = create((set, get) => ({
    mode: 'admin',
    customerTypes: [],
    staircases: [],
    risers: [],
    roughOpeningSizes: [],
    falseFronts: [],
    customerType: null,
    staircaseType: null,
    riserType: null,
    roughOpeningType: null,
    falseFrontType: null,
    numBoxTreads: 0,
    numDoubleTreadsOpen: 0,
    numSingleBullnose: 0,
    numDoubleBullnose: 0,
    numFalseFronts: 0,
    numFlareUpgrade: 0,
    price: 0,
    pricingChart: {
        retail: 5.00,
        nonAccount: 4.00,
        account: 3.00,
        wholesale: 2.00
    },
    subtotal: 0,
    treads: []
}));

const Calculator = () => {
    let customerTypes = [];
    let staircaseTypes = [];
    let risers = [];
    let roughOpeningSizes = [];
    let falseFrontTypes = [];
    let treads = [];
    const myStore = useStore();

    async function fetchTreads() {
        try {
            await DataStore.query(Treads).then(result => {
                if (result) {
                    result.forEach(item => {
                        treads.push({
                            id: item.id,
                            name: item.name,
                            description: item.description,
                            status: item.status,
                            price: item.price
                        });
                    });
                    useStore.setState({ treads: treads })
                    console.log('WHOLE THING', useStore.getState());
                }
            });
        } catch (error) {
            console.log('error: ', error);
        }
    }

    async function fetchCustomerTypes() {
        try {
            await DataStore.query(CustomerTypes).then(result => {
                    if (result) {
                        result.forEach(item => {
                            customerTypes.push({ id: item.id, name: item.name, value: item.name });
                        })
                        useStore.setState({ customerTypes: customerTypes });
                    }
                }
            );
        } catch (err) {
            console.log('Error in async function: ', err);
        }
    }

    async function fetchStaircases() {
        try {
            await DataStore.query(Staircases).then(result => {
                    if (result) {
                        result.forEach(item => {
                            staircaseTypes.push({ id: item.id, name: item.name });
                        })
                        useStore.setState({ staircases: staircaseTypes });
                    }
                }
            );
        } catch (err) {
            console.log('Error in async function: ', err);
        }
    }

    async function fetchRisers() {
        try {
            await DataStore.query(Risers).then(result => {
                    if (result) {
                        result.forEach(item => {
                            risers.push({ id: item.id, name: item.name });
                        })
                        useStore.setState({ risers: risers });
                    }
                }
            );
        } catch (err) {
            console.log('Error in async function: ', err);
        }
    }

    async function fetchRoughOpeningSizes() {
        try {
            await DataStore.query(RoughOpeningSizes).then(result => {
                    if (result) {
                        result.forEach(item => {
                            roughOpeningSizes.push({ id: item.id, label: item.label });
                        })
                        useStore.setState({ roughOpeningSizes: roughOpeningSizes });
                    }
                }
            );
        } catch (err) {
            console.log('Error in async function: ', err);
        }
    }

    async function fetchFalseFronts() {
        try {
            await DataStore.query(FalseFronts).then(result => {
                    if (result) {
                        result.forEach(item => {
                            falseFrontTypes.push({ id: item.id, label: item.label });
                        })
                        useStore.setState({ falseFronts: falseFrontTypes });
                    }
                }
            );
        } catch (err) {
            console.log('Error in async function: ', err);
        }
    }

    function getTreadPrice(id): float {
        let price = 0;
        for (let i = 0; i < myStore.treads.length; i ++) {
            switch(myStore.customerType) {
                case 'retail':
                    price = myStore.treads[i]['price']['retail'];
                    break;
                case 'non-account':
                    price = myStore.treads[i]['price']['nonAccount'];
                    break;
                case 'account':
                    price = myStore.treads[i]['price']['account'];
                    break;
                case 'wholesale':
                    price = myStore.treads[i]['price']['wholesale'];
                    break;
                default:
                    price = myStore.treads[i]['price']['retail'];
            }

        }
        return price;
    }
    
    function subtotal() {
        const allBoxTreads = useStore.getState().numBoxTreads;
        const allDoubleTreadsOpen = useStore.getState().numDoubleTreadsOpen;
        const allDoubleNullnose = useStore.getState().numDoubleBullnose;
        const allFalseFronts = useStore.getState().numFalseFronts;
        const allSingleBullnose = useStore.getState().numSingleBullnose;
        const allFlareUpgrade = useStore.getState().numFlareUpgrade;
        let lSub = allBoxTreads+allDoubleTreadsOpen+allDoubleNullnose+allSingleBullnose+allFalseFronts+allFlareUpgrade;
        // Get selected tread price based on type of customer
        let price = useStore.getState().price * lSub;
        return price;
    }

    useEffect(() => {
        fetchCustomerTypes();
        fetchStaircases();
        fetchRisers();
        fetchRoughOpeningSizes();
        fetchFalseFronts();
        fetchTreads();
    }, []);

    return (
        <div className={ 'calculator-wrapper-outer' }>
            <div className={ 'calculator-wrapper-left' }>
                <SelectField className={ 'compleat-select' } size="small"
                             label="Treads"
                             isDisabled={ false }
                             labelHidden={ false }
                             onChange={ (e) => {
                                 useStore.setState({price: getTreadPrice(e.target.value)});
                                 console.log('', useStore.getState());
                             }}
                             placeholder="Choose Tread">
                    {
                        useStore(state => state.treads).map((item, index) => {
                            return <option id={item.id} key={ item.id } value={ item.id }>{ item.name }</option>
                        })
                    }
                </SelectField>
                <SelectField className={ 'compleat-select' } size="small"
                             label="Customer"
                             isDisabled={ false }
                             labelHidden={ false }
                             onChange={ (e) => {
                                 useStore.setState({ customerType: e.target.value });
                             } }
                             placeholder="Choose Customer Type">
                    {
                        useStore(state => state.customerTypes).map((item, index) => {
                            return <option key={ index } value={ item.name }>{ item.name }</option>
                        })
                    }
                </SelectField>
                <SelectField className={ 'compleat-select' } size="small"
                             label="Staircase"
                             isDisabled={ false }
                             labelHidden={ false }
                             onChange={ (e) => {
                                 useStore.setState({ staircaseType: e.target });
                             } }
                             placeholder="Choose Staircase Type">
                    {
                        useStore(state => state.staircases).map((item, index) => {
                            return <option key={ index } value={ item.id }>{ item.name }</option>
                        })
                    }
                </SelectField>
                <SelectField className={ 'compleat-select' } size="small"
                             label="Risers"
                             isDisabled={ false }
                             labelHidden={ false }
                             onChange={ (e) => {
                                 useStore.setState({ riserType: e.target });
                             } }
                             placeholder="Choose Riser">
                    {
                        useStore(state => state.risers).map((item, index) => {
                            return <option key={ index } value={ item.id }>{ item.name }</option>
                        })
                    }
                </SelectField>
                <SelectField className={ 'compleat-select' } size="small"
                             label="Rough Opening Sizes"
                             isDisabled={ false }
                             labelHidden={ false }
                             onChange={ (e) => {
                                 useStore.setState({ roughOpeningType: e.target });
                             } }
                             placeholder="Choose Rough Opening Size">
                    {
                        useStore(state => state.roughOpeningSizes).map((item, index) => {
                            return <option key={ index } value={ item.id }>{ item.label }</option>
                        })
                    }
                </SelectField>
                <SelectField className={ 'compleat-select' } size="small"
                             label="False Front Types"
                             isDisabled={ false }
                             labelHidden={ false }
                             onChange={ (e) => {
                                 useStore.setState({ falseFrontType: e.target });
                             } }
                             placeholder="Choose False Front Type">
                    {
                        useStore(state => state.falseFronts).map((item, index) => {
                            return <option key={ index } value={ item.id }>{ item.label }</option>
                        })
                    }
                </SelectField>
            </div>
            <div className={ 'calculator-wrapper-middle' }>
                <StepperField className={ 'compleat-stepper' } size="small"
                              label="# of Box Treads"
                              placeholder=""
                              onStepChange={ value => {
                                  useStore.setState({ numBoxTreads: value} );
                                  useStore.setState({ subtotal: subtotal() });
                                  console.log('', useStore.getState());
                              } }
                />
                <StepperField className={ 'compleat-stepper' } size="small"
                              label="# of Double Treads Open"
                              placeholder=""
                              onStepChange={ (value) => {
                                  useStore.setState({ numDoubleTreadsOpen: value });
                                  subtotal();
                              } }
                />
                <StepperField className={ 'compleat-stepper' } size="small"
                              label="# of Single Bullnose"
                              placeholder=""
                              onStepChange={ (value) => {
                                  useStore.setState({ numSingleBullnose: value });
                                  subtotal();
                              } }
                />
                <StepperField className={ 'compleat-stepper' } size="small"
                              label="# of Double Bullnose"
                              placeholder=""
                              onStepChange={ (value) => {
                                  useStore.setState({ numDoubleBullnose: value });
                                  subtotal();
                              } }
                />
                <StepperField className={ 'compleat-stepper' } size="small"
                              label="# of False Fronts"
                              placeholder=""
                              onStepChange={ (value) => {
                                  useStore.setState({ numFalseFronts: value });
                                  subtotal();
                              } }
                />
                <StepperField className={ 'compleat-stepper' } size="small"
                              label="# of Flare Upgrade"
                              placeholder=""
                              onStepChange={ (value) => {
                                  useStore.setState({ numFlareUpgrade: value });
                                  subtotal();
                              } }
                />
            </div>
            <div className={ 'calculator-wrapper-right' }>
                <p></p>
                <Text className="price-text">{ '$' + useStore(state => state.subtotal) }</Text>
            </div>
        </div>
    );
}

const theme: Theme = {
    name: 'compleat-theme',
    tokens: {
        colors: {
            font: {
                primary: { value: '#ffffff' }
            },
        },
    }
};

const LoginForm = () => {
    const { tokens } = useTheme();
    return (
        <div className={ "compleat-wrapper" }>
            <Card>
                <Flex direction="column" alignItems="flex-start">
                    <Authenticator color={ tokens.colors.font.primary }>
                        { ({ signOut, user }) => (
                            <main>
                                <Heading><Button onClick={ signOut }>Sign out</Button></Heading>
                                <Calculator/>
                            </main>
                        ) }
                    </Authenticator>
                </Flex>
            </Card>
        </div>
    )
}

function Compleat() {
    return (
        <div className={ 'app-wrapper-compleat' }>
            <AmplifyProvider theme={ theme }>
                <LoginForm/>
            </AmplifyProvider>
        </div>
    )
}

export default Compleat;
