import React, { useContext, useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { AppContext } from './AppContext';
import { isMobile } from 'react-device-detect';

const SLIDESHOW_DATA = {
    images: [
        { fileName: 'ss-00.jpg', fileNameMobile: 'ss-00-p.png', color: 'rgba(0,0,0,.5)', time: 5 },
        { fileName: 'ss-01.jpg', fileNameMobile: 'ss-01-p.png', color: 'rgba(255,255,255,.2)', time: 5 },
        { fileName: 'ss-02.jpg', fileNameMobile: 'ss-02-p.png', color: 'rgba(104,162,58,.5)', time: 5 },
        { fileName: 'ss-03.jpg', fileNameMobile: 'ss-03-p.png', color: 'rgba(0,196,157,.4)', time: 5 },
        { fileName: 'ss-05.png', fileNameMobile: 'ss-04-p.png', color: 'rgba(0,0,0,.5)', time: 5 },
        { fileName: 'ss-07.png', fileNameMobile: 'ss-05-p.png', color: 'rgba(0,0,0,.5)', time: 5 },
    ]
};

let PAGE_CONTENT_DATA = {
    pages: [
        {
            name: 'software development',
            content: `Software creation for web and mobile. Website development in React or Angular, with hosting available on AWS and Google Firebase. <p></p>Discover your vision with a collaborative prototype in Adobe XD.`
        },
        {
            name: 'Web and Print Design',
            content: `Design with deep insight into the aesthetic of your products.<p></p>3D product display and interactivity.`
        },
        { name: 'Inquiries', content: '' },
    ]
};

let glowColor = 'rgba(0,0,0,.5)';

const Preloader = () => {
    let { showPreloader, setShowPreloader } = useContext(AppContext)[ 0 ];

    async function loadMediaItems() {
        const imagesPromiseList: Promise<any>[] = [];
        for (const img of SLIDESHOW_DATA.images) {
            const fileName = isMobile ? img.fileNameMobile : img.fileName;
            imagesPromiseList.push(preloadImage(fileName))
        }

        await Promise.all(imagesPromiseList).then(() => {
            setShowPreloader(false);
        });
    }

    const preloadImage = (src: string) => {
        return new Promise((resolve, reject) => {
            const img = new Image();

            img.onload = () => {
                resolve(img);
            }

            img.onerror = () => {
                reject(src);
            }
            img.src = src;
        });
    }

    useEffect(() => {
        loadMediaItems();
    }, []);
    return (
        <div className={ showPreloader ? 'preloader' : 'preloader hide' }><div className="nig"></div></div>
    );
}


const Slideshow = expiryTimeStamp => {

    const [fileName, setFileName] = useState(SLIDESHOW_DATA.images[ 0 ][isMobile ? 'fileNameMobile' : 'fileName']);
    const [imageNum, setImageNum] = useState(0);
    const time = new Date();

    const onExpire = () => {
        setImageNum((imageNum < SLIDESHOW_DATA.images.length && SLIDESHOW_DATA.images[ imageNum + 1 ] !== undefined) ?
            imageNum + 1
            : 0);
        setFileName(SLIDESHOW_DATA.images[ imageNum ][isMobile ? 'fileNameMobile' : 'fileName']);
        glowColor = SLIDESHOW_DATA.images[ imageNum ].color;

        startSlideshow();
    };

    const startSlideshow = () => {
        setFileName(SLIDESHOW_DATA.images[ imageNum ][isMobile ? 'fileNameMobile' : 'fileName']);
        time.setSeconds(time.getSeconds() + SLIDESHOW_DATA.images[ imageNum ].time);
        setTimeout(() => {
            restart(time, true);
        }, 0);
    };

    const toggle = () => {
        isRunning ? pause() : resume();
    };

    const {
        isRunning,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimeStamp, onExpire: onExpire, autoStart: true });

    useEffect(() => {
        startSlideshow();
        return function cleanup() {
            expiryTimeStamp = new Date();
            setImageNum(0);
        };
    }, []);

    return (
        <div>
            <div className={ 'slideshow-inner-wrapper' } onClick={ toggle }
                 style={ { backgroundImage: `url(${ fileName })`, boxShadow: `0px 0px 45px ${ glowColor }` } }></div>
        </div>
    );
}

const Profile = () => {
    const [pageName, setPageName] = useState('none');
    const [pageContent, setPageContent] = useState('');

    const showPreloader = useContext(AppContext)[ 0 ].showPreloader;
    const showHero = useContext(AppContext)[ 1 ].showHero;
    const setShowHero = useContext(AppContext)[ 1 ].setShowHero;
    const showNavigation = useContext(AppContext)[ 2 ].showNavigation;
    const setShowNavigation = useContext(AppContext)[ 2 ].setShowNavigation;
    const showContent = useContext(AppContext)[ 3 ].showContent;
    const setShowContent = useContext(AppContext)[ 3 ].setShowContent;

    const setContent = (pageName) => {
        setShowContent(true);
        setPageName(pageName);
        setShowHero(false);

        PAGE_CONTENT_DATA.pages.forEach(page => {
            if (page.name.toLowerCase() === pageName.toLowerCase()) {
                setPageContent(page.content);
            }
        });

    }

    return (
        <div className={ 'full-wrapper' }>
            <Preloader/>
            <div className='profile-wrapper'>
                <div className={ !showPreloader && showHero ? 'slideshow-wrapper' : 'slideshow-wrapper hide' }>
                    <Slideshow/>
                </div>
                <div className={ showContent ? 'content-wrapper show' : 'content-wrapper' }>
                    <div className='close' onClick={ () => {
                        setShowContent(false);
                        setShowHero(true);
                        setShowNavigation(true);
                        setPageName('home');
                    } }></div>
                    <div className='site-title'>{ pageName }</div>
                    <div className='content'
                         dangerouslySetInnerHTML={ { __html: pageContent } }></div>
                </div>
                <div className={ showNavigation ? 'navigation-wrapper' : 'navigation-wrapper hide' }>
                    <div className={ showContent ? 'site-title hide' : 'site-title' }>mike britton</div>
                    <div className={ showContent ? 'pages-wrapper hide' : 'pages-wrapper' }>
                        <div className='software-development' onClick={ () => {
                            setContent('software development');
                        } }><span>software development</span>
                        </div>
                        <div className='web-and-print' onClick={ () => {
                            setContent('web and print design');
                        } }><span>web and print design</span>
                        </div>
                    </div>
                    <div className={ showContent ? 'inquiries hide' : 'inquiries' } onClick={ () => {
                        setShowContent(false);
                        setShowHero(false);
                        setShowNavigation(false);
                        setPageName('Inquiries');
                        document.location = 'mailto:mbritton72@gmail.com?subject=inquiry';
                    } }><span>inquiries</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;
