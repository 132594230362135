import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Library } from '@observablehq/stdlib';


interface IProps {
    data: number[];
}

let DEFAULT_DATA = {
    "name": "mike britton",
    "data": { "background": "home" },
    "children": [
        {
            "name": "work",
            "data": { "image": "fg-work.png", "textPosition": { "x": 375, "y": 30 }, html: "Work time", "background": "work" },
            "children": [
                {
                    "name": "code",
                    "children": [
                        { "name": "Angular", "value": 8, "data": { "url": "http://www.google.com" } },
                        {
                            "name": "React",
                            "value": 8,
                            "data": { "html": "Here's some text content. <br> Here's some more" }
                        },
                        { "name": "D3", "value": 8 }
                    ]
                },
                {
                    "name": "design",
                    "children": [
                        {
                            "name": "draw",
                            "value": 8,
                            "data": { "html": "Here's some text content. <br> Here's some more" }
                        },
                        { "name": "model", "value": 8 },
                        { "name": "ideas", "value": 18 }
                    ]
                }
            ]
        },
        {
            "name": "play",
            "data": { "image": "fg-play.png", "textPosition": { "x": 375, "y": 30 }, html: "Play time", "background": "canvas-play.png" },
            "children": [
                {
                    "name": "cycling",
                    "value": 26,
                    "data": {  }
                },
                { "name": "art", "value": 16, "data": { "html": "Here's some text content. <br> Here's some more" } }
            ]
        }
    ]
};

let backgroundImageClass = 'home';

// Placeholder for static treemap
function MyD3TreeMap(props: IProps) {
    const svgRef = useRef();
    let height = 800, width = 800;
    const x = d3.scaleLinear().rangeRound([0, width]);
    const y = d3.scaleLinear().rangeRound([0, height]);

    const library = new Library();

    useEffect(() => {
        let svg = d3.select('svg');

        function position(group, root) {
            group.selectAll("g")
                .attr("transform", d => d === root ? `translate(0,0)` : `translate(${ x(d.x0) },${ y(d.y0) + 50 })`)
                .select("rect")
                .attr("width", d => d === root ? width : x(d.x1) - x(d.x0))
                .attr("height", d => d === root ? 36 : y(d.y1) - y(d.y0));
        }

        function render(group, root) {
            const node = group
                .selectAll("g")
                .data(root.children.concat(root))
                .join("g");

            node.filter(d => d === root ? d.parent : d.children)
                .attr("cursor", "pointer");

            node.append("title").text(d => getNameValuePair(d));

            node.append("rect")
                .attr("id", d => (d.leafUid = library.DOM.uid("leaf")).id)
                .attr("fill", d => d === root ? "rgba(0,0,0,.9)" : d.children ? "rgba(0,0,0,.9)" : "rgba(0,0,0,.9)")
                .attr("cursor", "pointer")
                .attr("stroke", 'black')
                .attr("stroke-width", 1);

            group.call(position, root);
        }

        function getNameValuePair(d): any {
            return <span>d.data.name</span> + '\n' + d.value;
        }

        function tile(node, x0, y0, x1, y1) {
            d3.treemapBinary(node, 0, 0, width, height);
            for (const child of node.children) {
                child.x0 = x0 + child.x0 / width * (x1 - x0);
                child.x1 = x0 + child.x1 / width * (x1 - x0);
                child.y0 = y0 + child.y0 / height * (y1 - y0);
                child.y1 = y0 + child.y1 / height * (y1 - y0);
            }
        }

        let treeMap = data => d3.treemap().tile(tile)
        (d3.hierarchy(data)
            .sum(d => d.value)
            .sort((a, b) => b.value - a.value));

        // Call render
        svg.append("g").call(render, treeMap(props.data));

        return svg.node();
    }, [props.data, svgRef.current])

    return (<svg className='d3-treemap' ref={ svgRef }></svg>);
}

function MyD3ZoomableTreeMap(props: IProps) {
    const svgRef = useRef();

    // @observablehq/stdlib
    const library = new Library();

    let height = window.innerHeight;
    let width = window.innerWidth;
    const x = d3.scaleLinear().rangeRound([0, width]);
    const y = d3.scaleLinear().rangeRound([0, height]);
    const divElement = document.querySelector('body');
    let homeName = props.data.name;

    const resizeObserver = new ResizeObserver((e) => {
        console.log('Our base object: svgRef', svgRef);
    });
    resizeObserver.observe(divElement);

    useEffect(() => {
        let svg = d3.select('svg');

        function position(group, root) {
            group.selectAll("g")
                .attr("transform", d => d === root ? `translate(0,0)` : `translate(${ x(d.x0) },${ y(d.y0) + 50 })`)
                .select("rect")
                .attr("opacity", '.2')
                .attr("width", d => d === root ? width : x(d.x1) - x(d.x0))
                .attr("height", d => d === root ? 36 : y(d.y1) - y(d.y0));
        }

        function setBackgroundImage(d): string {
            backgroundImageClass = d.data.data.background;
        }

        function render(group, root) {
            const node = group
                .selectAll("g")
                .data(root.children.concat(root))
                .join("g");

            function zoomIn(d) {
                // .transition().duration(300).ease(d3.easeQuadIn).style("opacity", 0)
                const group0 = group.attr("pointer-events", "none");
                const group1 = group = svg.append("g").call(render, d);

                x.domain([d.x0, d.x1]);
                y.domain([d.y0, d.y1]);

                svg.transition()
                    .duration(700)
                    .call(t => group0.transition(t).remove()
                        .call(position, d.parent))
                    .call(t => group1.transition(t)
                        .attrTween("opacity", () => d3.interpolate(0, 1))
                        .call(position, d));
            }

            // When zooming out, draw the old nodes on top, and fade them out.
            function zoomOut(d) {
                const group0 = group.attr("pointer-events", "none");
                const group1 = group = svg.insert("g", "*").call(render, d.parent);

                x.domain([d.parent.x0, d.parent.x1]);
                y.domain([d.parent.y0, d.parent.y1]);

                svg.transition()
                    .duration(700)
                    .call(t => group0.transition(t).remove()
                        .attrTween("opacity", () => d3.interpolate(1, 0))
                        .call(position, d))
                    .call(t => group1.transition(t)
                        .attrTween("opacity", () => d3.interpolate(0, 1))
                        .call(position, d.parent));
            }

            node.filter(d => d === root ? d.parent : d.children)
                .attr("cursor", "pointer")
                .on("click", (event, d) => {
                    console.log('click!!', d);
                    // Page transitions here
                    setBackgroundImage(d);
                    // Select page title object and do its teardown

                    d3.selectAll(".foo").transition()
                        .duration(300).ease(d3.easeQuadIn).style("opacity", 0);

                    // Select image and transition
                    d3.selectAll(".fart").transition()
                        .duration(300).ease(d3.easeQuadIn).style("opacity", 0);

                    (d === root) ? zoomOut(root) : zoomIn(d);
                });

            // Background image for Hero
            node.append("foreignObject")
                .attr("overflow", "visible")
                .attr("width", "0")
                .attr("height", "0")
                .append("xhtml:div")
                .attr("class", d => d !== undefined ? backgroundImageClass : null);

            // Main tree card
            node.append("rect")
                .attr("id", d => (d.leafUid = library.DOM.uid("leaf")).id)
                .attr("fill", d => d === root ? "rgba(0,0,0,.5)" : d.children ? "rgba(0,0,0,.5)" : "rgba(0,0,0,.5)")
                .attr("cursor", "pointer")
                .attr("stroke", '#111111')
                .attr("opacity", '.2')
                .attr("pointer-events", "none")
                .attr("stroke-width", 1);

            node.append("foreignObject")
                .attr("preserveAspectRatio", "xMinYMin meet")
                .attr("overflow", "visible")
                .append("xhtml:div")
                    .attr("class", d => d !== undefined && d !== root ? getHeroImageClass(d) + " fart" : null);

            // Back Button (on internal screens only)
            console.log('homeName', homeName);
            if (root.data.name.toLowerCase() !== homeName) {
                console.log('root.data.name.toLowerCase() !== homeName', root.data.name.toLowerCase());
                node.append("rect")
                    .attr("class", "foo")
                    .attr("x", 0)
                    .attr("y", 0)
                    .attr("width", "36px")
                    .attr("height", "36px")
                    .attr("fill", "#000809");
            } else {
                console.log('');
            }
            

            if (root.data.name === homeName) {
                // Title background
                node.append("rect")
                    .attr("class", "foo")
                    .attr("x", d => d === root && homeName !== d.data.name ? 37 : 0)
                    .attr("y", 0)
                    .attr("width", "140px")
                    .attr("height", "36px");
            }

            // Add title
            node.append("text")
                .attr("clip-path", d => d.clipUid)
                .attr("font-weight", d => d === root ? "bold" : null)
                .attr("x", d => (d === root && homeName !== d.data.name) ? 50 : 10)
                .selectAll("tspan")
                .data(d => (d === root ? d.data.name : d.data.name).split(/(?=[A-Z][^A-Z])/g))
                .join("tspan")
                .attr("y", (d, i, nodes) => `${ (i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9 }em`)
                .attr("font-weight", (d, i, nodes) => i === nodes.length - 1 ? "normal" : null)
                .attr("fill", "#fff")
                .attr("opacity", (d) => d === root ? 0 : 1)
                .text(d => d);

            // root.data.name.toLowerCase() === homeName
            // node.append("title").text(d => getNameValuePair(d));

            // Add text if it exists
            node.append("foreignObject")
                .attr("width", 0)
                .attr("height", 0)
                .attr("overflow", "visible")
                .attr("x", d => (d !== root && d.data && d.data.data && d.data.data.textPosition) ? d.data.data.textPosition.x : 0)
                .attr("y", d => (d !== root && d.data && d.data.data && d.data.data.textPosition) ? d.data.data.textPosition.y : 0)
                .append("xhtml:div")
                .attr("class", "text-div")
                .attr("style", "width: 300px;display:" + (d => (d !== root && hasText(d)) ? "block" : "none"))
                .style("font", "10px 'Helvetica'")
                .html(d => (d.data.data && d !== root) ? d.data.data.html : null);

            d3.selectAll("title").remove();

            group.call(position, root);
        }

        function getNameValuePair(d): any {
            return <span>d.data.name</span> + '\n' + d.value;
        }

        function getHeroImageClass(d): string {
            return 'img-hero-' + d.data.name;
        }



        function hasText(d): boolean {
            return d.data.data && d.data.data.html && d.data.data.html.length > 0;
        }

        function tile(node, x0, y0, x1, y1) {
            d3.treemapBinary(node, 0, 0, width, height);
            for (const child of node.children) {
                child.x0 = x0 + child.x0 / width * (x1 - x0);
                child.x1 = x0 + child.x1 / width * (x1 - x0);
                child.y0 = y0 + child.y0 / height * (y1 - y0);
                child.y1 = y0 + child.y1 / height * (y1 - y0);
            }
        }

        let treeMap = data => d3.treemap().tile(tile)
        (d3.hierarchy(data)
            .sum(d => d.value)
            .sort((a, b) => b.value - a.value));

        // Call render
        let group = svg.append("g").call(render, treeMap(props.data));

        return svg.node();
    }, [props.data, svgRef.current]);

    return (<svg className='d3-treemap' ref={ svgRef }></svg>);
}

function Home() {
    const datums = DEFAULT_DATA;
    return (
        // <MyD3TreeMap data={ datums }/>
        <div className={ 'app-wrapper img-bg-' + backgroundImageClass }><MyD3ZoomableTreeMap data={ datums }/></div>
    )
}

export default Home;
