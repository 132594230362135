import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({children}) => {
    const [showPreloader, setShowPreloader] = useState(true);
    const [showHero, setShowHero] = useState(true);
    const [showNavigation, setShowNavigation] = useState(true);
    const [showContent, setShowContent] = useState(false);

    return (
        <AppContext.Provider value={[ {showPreloader, setShowPreloader}, {showHero, setShowHero}, {showNavigation, setShowNavigation}, {showContent, setShowContent} ]}>
            { children }
        </AppContext.Provider>
    );
}

