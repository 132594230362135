import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import MikeBrittonDotCom from "./MikeBrittonDotCom";
import Home from "./Home";
import Compleat from "./Compleat";
import { Amplify } from 'aws-amplify';

import awsExports from './aws-exports';
import Profile from "./Profile";
import { AppProvider } from "./AppContext";
// For Compleat Amplify system
//Amplify.configure(awsExports);

function App() {
  return (
      <AppProvider>
      <Router>
        <div>
          <Switch>
            <Route exact path="/">
              <Profile />
            </Route>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/mb">
              <MikeBrittonDotCom />
            </Route>
            <Route path="/compleat">
              <Compleat />
            </Route>
            <Route path="/compleat-admin">
              <Compleat />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
          </Switch>
        </div>
      </Router>
      </AppProvider>
  );
}
// For Compleat Amplify system
// export default withAuthenticator(App);
export default App;
